import React from 'react';
import { Link } from 'gatsby';

import {
  Layout,
  SEO,
  PageHero,
  GradientContainer,
  MembershipForm,
  TextWithImage,
  StartFreeTrial,
  Instagram,
  Map,
} from '../components';
import { useGraphQL } from '../hooks';

function MembershipPage() {
  const { membership, deadlift } = useGraphQL();

  return (
    <Layout>
      <SEO title="Membership" />
      <PageHero heading="Membership" image={membership.childImageSharp.fluid} />
      <div className="">
        <Link
          className="flex justify-center mx-auto my-12 text-lg font-bold text-center underline md:text-2xl"
          to="/recovery-services"
        >
          To Book Our Recovery Centre Services - Click Here
        </Link>
      </div>
      <GradientContainer>
        <div className="grid gap-24">
          <MembershipPackages />
          <MembershipFirstSection />
          <StartFreeTrial image={deadlift.childImageSharp.fluid} />
        </div>
      </GradientContainer>
      <Instagram />
      <Map />
    </Layout>
  );
}

function MembershipFirstSection() {
  return (
    <article className="relative grid items-center w-full max-w-5xl mx-auto lg:grid-cols-1">
      <MembershipForm />
    </article>
  );
}

function MembershipPackages() {
  const { medicineBall } = useGraphQL();
  return (
    <TextWithImage
      eyebrowText="See our"
      heading="nXtLvL Memberships &amp; packages"
      ctaLabel="Join now"
      ctaLink="/membership/#membership-form"
      image={medicineBall.childImageSharp.fluid}
      aspectRatio="aspect-ratio-3/4"
      reverse
      pricing
    >
      <dl>
        <div>
          <div className="text-lg">
            You can now sign up online -{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://clients.mindbodyonline.com/classic/ws?studioid=5738153&stype=40"
              className=""
            >
              click here to sign up.
            </a>
          </div>
          <br />
          <dt className="inline font-bold uppercase">
            Unlimited Gym Membership{' '}
          </dt>
          <dd className="">$60 per week (unlimited classes and open gym)</dd>
          <dd className="">$45 per week if on a 6 month locked in contract</dd>
          <dd className="">$40 per week if on a 12 month lock in contract</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            Teens/Student Unlimited Gym Membership (aged 15 - 18){' '}
          </dt>
          <dd className="">$35 per week (unlimited classes and open gym)</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">Open Gym Membership </dt>
          <dd className="">
            $25 per week. This membership is for those seeking independent gym
            use – no coaching, programming or classes, just use of the epic
            facilities and equipment. If you would like to do a class and have
            this membership it will be charged at $15 per person per class.
          </dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">10 Visit Gym Pass </dt>
          <dd className="">$200</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">Gym Drop In Session </dt>
          <dd className="">$25 per class/open gym session</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">Teens (aged 15 - 18) </dt>
          <dd className="">$15 per class</dd>
        </div>
        <br />
        <div className="font-bold underline">RECOVERY CENTRE SERVICES</div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            Infrared Sauna (30 min session){' '}
          </dt>
          <dd className="">
            $25 single session (additional people up to 5 total in one group are
            an additional $10 per person on top of the single session price)
          </dd>
          <dd className="">5 Pack - $110</dd>
          <dd className="">10 Pack - $200</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">(60 min session) </dt>
          <dd className="">
            $40 single session (additional people up to 5 total in one group are
            an additional $15 per person on top of the single session price)
          </dd>
          <dd className="">5 Pack - $160</dd>
          <dd className="">10 Pack - $320</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">NORDIC ICE BATH</dt>
          <dd className="">
            $25 – Single Session (additional person in same session $5 per
            person extra up to max of 4 people)
          </dd>
          <dd className="">5 Pack - $110</dd>
          <dd className="">10 Pack - $200</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            HOT HYDROTHERAPY SPA (30 min session){' '}
          </dt>
          <dd className="">
            30 min Single Session - $25 (additional people up to 3 max in one
            group are at an additional charge of $10 per person on top of the
            single session price)
          </dd>
          <dd className="">5 Pack - $110</dd>
          <dd className="">10 Pack - $200</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">(60 min session) </dt>
          <dd className="">
            $40 (additional people up to 3 max in one group are at an additional
            charge of $15 per person on top of the single session price)
          </dd>
          <dd className="">5 Pack - $220</dd>
          <dd className="">10 Pack - $350</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            CONTRAST THERAPY SESSION{' '}
          </dt>
          <dd className="">
            Alternating between 1-3 mins in Hot Hydrotherapy Spa with 1 min in
            Nordic Ice Bath finishing with 1 min in the Ice Bath
          </dd>
          <dd className="">
            20min Session - $30 Single Session (additional people up to 4 max at
            an additional charge of $15 per person on top of the single session
            price)
          </dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            NORMATEC COMPRESSION BOOTS{' '}
          </dt>
          <dd className="">30 min Session - $25 Single Session</dd>
          <dd className="">5 Pack - $110</dd>
          <dd className="">60 min Session - $40</dd>
          <dd className="">5 Pack - $160</dd>
        </div>
        <br />
        <div className="font-bold underline">RECOVERY CENTRE SERVICES</div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">FIRE & ICE </dt>
          <dd className="">
            50 min Infrared Sauna + 10 min Ice Bath - $55 Single Session
          </dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">COMPRESSION & ICE </dt>
          <dd className="">
            30 min Normatec Boots + 10 min Ice Bath - $35 Single Session
          </dd>
          <dd className="">
            60 min Normatec Boots + 10 min Ice Bath - $55 Single Session
          </dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            COMPRESSION + HYDRO SPA + ICE BATH/CONTRAST THERAPY{' '}
          </dt>
          <dd className="">
            30 min Normatec Boots + 20 min Hydro Spa + 10 min Ice Bath - $65
            Single Session
          </dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">
            PERSONAL TRAINING – 1 ON 1{' '}
          </dt>
          <dd className="">30 min Session - $60</dd>
          <dd className="">60 min Session - $100</dd>
        </div>
        <br />
        <div>
          <dt className="inline font-bold uppercase">SPORTS MASSAGE</dt>
          <dd className="">30 min Session - $60</dd>
          <dd className="">60 min Session - $100</dd>
        </div>
      </dl>
      {/* <h4 className="font-bold uppercase">
        Casual sauna visits are as follows:
      </h4>
      <ul>
        <li>1 person $30</li>
        <li>2 people $35</li>
        <li>3 people $40</li>
        <li>4 people $45</li>
        <li>5 people $50</li>
      </ul>
      <dl>
        <div>
          <dt className="inline font-bold uppercase">
            Combined Unlimited Gym &amp; Sauna
          </dt>
          <dd className="inline">$75 per week</dd>
        </div>
        <div>
          <dt className="mt-4 font-bold uppercase">Personal training</dt>
          <dd>1 hour = $80</dd>
          <dd>&frac12; hour = $50</dd>
        </div>
        <div>
          <dt className="mt-4 font-bold uppercase">Massage</dt>
          <dd>1 hour = $80</dd>
          <dd>&frac12; hour = $50</dd>
        </div>
      </dl> */}
    </TextWithImage>
  );
}

export default MembershipPage;
